<template>
    <div id="checkMSSBIS" class="container-fluid m-0 p-0">
        <div class="row m-0 p-3 justify-content-center">
            <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
                <div class="row m-0 p-4">
                    <div class="col-12 mx-0 mb-3 mb-xl-0">
                        <p class="font-1-2s font-600">Check MSS to BIS</p>
                    </div>
                </div>
                <div class="row m-0 pl-4 pb-4">
                    <div class="col-12 mx-0 mb-3 mb-xl-0">
                        <p class="px-0 font-700 w-100 font-0-9s" style="display: flex">
                            จำนวน &nbsp;
                            <span class="text-main">
                                {{ new Intl.NumberFormat().format(totalRows) }}
                            </span>
                            &nbsp; รายการ
                        </p>
                        <b-table hover outlined show-empty class="form-control-sm font-0-8s" :items="items"
                            :fields="fields" head-variant="light" :current-page="page_num" :per-page="page_size"
                            :tbody-tr-class="rowClass">
                            <template #cell(show_details)="row">
                                <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                                    {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
                                </b-button>
                            </template>

                            <template #row-details="row">
                                <TableDetail :items="row" :key="row.Name" v-if="!row.detailsShowing"
                                    class="font-1-2s bg-white" />
                            </template>
                        </b-table>

                        <div class="col-12">
                            <div class="row justify-content-end">
                                <div class="col-6 col-xl-2 input-group-prepend input-group-sm">
                                    <select class="custom-select" v-model="page_size">
                                        <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                                            {{ e }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6 col-xl-3">
                                    <b-pagination v-model="page_num" :total-rows="totalRows" :per-page="page_size"
                                        align="fill" size="sm" class="my-0"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Check_MMS_BIS',
    data() {
        return {

        }
    },
}
</script>